@mixin font-regular-12() {
  font-size: 12px;
  font-weight: 400;
}

@mixin font-regular-12-bold() {
  font-size: 12px;
  font-weight: 500;
}

.profile-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.profile-photo {
  .max-size-photo {
    @include font-regular-12;
    word-break: break-all;
  }

  .upload-photo {
    @include font-regular-12-bold;
    color: #017AFFFF;
    cursor: pointer;
  }

  .remove-photo {
    @include font-regular-12-bold;
    color: #D8392FFF;
    cursor: pointer;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .profile-photo {
    .upload-photo {
      -webkit-font-smoothing: inherit;
    }

    .remove-photo {
      -webkit-font-smoothing: inherit;
    }
  }
}
