.input {
  &-wrapper {
    margin-bottom: 14px;
    flex: 1;

    .label {
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 8px;
    }

    .label-icon {
      display: flex;
      margin-right: 8px;
    }

    .error {
      border-color: #FF4545;
    }

    .error-message {
      display: flex;
      flex: 1 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #FF4545
    }
  }
}

.no-margin {
  margin: 0;
}
