.stores-page{
  padding: 20px;

  .settings-page-title-container{
    display: flex;

    .settings-page-title{
      display: flex;
      flex-direction: column;

      .title{
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 5px;
      }

    }
  }

  .agents-avatars {
    display: flex;
    align-items: center;
    position: relative;
  }

  .agents-count {
    display: none;
  }

  .search-and-filter-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .search-and-filter{
      display: flex;
      width: 30%;
      padding-top: 15px;
    }

    .btn-custom{
      margin-right: 5px;
    }
  }

  .table-responsive{
    margin-bottom: 20px;
  }

  .table-pagination-container{
    display: flex;
    justify-content: flex-end;
  }
}
.store-phone-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  .title-wrapper {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 10px;
    span {
      margin-left: 5px;
    }
  }
  .input-wrapper{
    width: 100%;
    display: flex;
  }
}
.working-hours {
  &-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
  }

  &-label {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
  }
}

.picker-wrapper {
  display: flex;
  gap: 25px;
}

.store-details-wrapper {
  margin-top: 27px;
}
.conversation-assign-mode-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .title-wrapper {
    display: flex;
    align-items: center;
    .conversation-assign-mode-title {
      font-weight: 500;
      font-size: 15px;
      margin-left: 5px;
    }
    .conversation-assign-mode-icon {
      font-size: 25px;
      color: #9b9b9b;
    }
    .conversation-assign-mode-radio {
      font-size: 14px;
    }
  }
  .conversation-assign-mode-controls{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin: 5px;
  }
}

.users-in-store {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
    list-style: none;
    margin: 27px 0 0 0;
  }
  &-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  &-input {
    flex:1
  }
}

.agents-col {
  position: relative;
}

@media only screen and (max-width: 375px) {
  .stores-page {
    padding: 0;
    height: 667px;

    .settings-page-title{
      .title{
        display: none;
      }

    }

    .agents-col {
      padding: 0;
      width: 55px;
    }

    .agents-count {
      display: block;
      color: black;
    }

    .rest-agents {
      display: none;
    }

    table {
      border: none;

      .MuiTableCell-body {
        &:nth-child(3) {
          display: none;
        }
      }

      .MuiAvatarGroup-root {
        display: none;
      }

      .action-btn {
        width: 39px;
        height: 39px;

        span {
          display: none;
        }

        i {
          margin: 0;
        }
      }
    }

    .search-and-filter-container {
      align-items: flex-end;

      .search-and-filter {
        width: 100%;

        .search-input-wrapper{
          width: 100%;

          &>div {
            width: 90%;
          }
        }
      }
    }

    .btn-custom {
      width: 40px;
      height: 40px;

      span {
        display: none;
      }
    }

    .table-pagination-container {
      justify-content: center;
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {

  .table-pagination-container{
    margin-top: 50px;
    width: 100%;
    nav {
      width: 100%;
    }
  }
  .stores-page {
    padding-left: 10px;
    .search-and-filter-container {
      .search-and-filter {
        width: 95%;
      }
      .btn-custom {
        background-color: #FF7A59;
        margin-right: 17px;
        border-color: #FF7A59;
      }
    }
  }
  .first-cell {
    border-left: none !important;
    padding-left: 0px !important;
  }
  .last-cell {
    border-right: none!important;
  }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .timepicker-wrapper {
    max-width: none;
    width: 100%;
    .timepicker {
      display: block;
      .timepicker-input {
        min-width: 100%;
      }
    }
  }
  .selects-wrapper {
    div {
      .label {
        font-size: 13px;
      }
    }
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .stores-page {
    padding: 20px;
    .first-cell {
      position: relative;
      left: 10px;
    }
  }
}
