.assigned-agent {
  display: flex;
  flex-direction: column;
  padding: 1.625rem 1.25rem;
  border-bottom: 1px solid #ECECEE
}

.assigned-agent-body {
  display: flex;
  font-weight: 500;
  align-items: center;
  margin-top: 15px;
}

.reassign-link{
  text-decoration: underline;
  color: blue!important;
  cursor: pointer;
  &.disabled {
    color: #606060!important;
    pointer-events: none;
  }
}

.name-block{
  display: flex;
  flex-direction: column;
  position: relative;
}

.retailers-select-wrapper {
  position: absolute;
  background-color: white;
  height: 150px;
  width: 150px;
}
.assigned-agent-skeleton {
  display: flex;
  align-items: center;
  .assigned-agent-text-skeleton {
    margin-top: 5px;
    margin-left: 10px;
  }
}
