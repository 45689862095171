.payment-request-container {
  background-color: #f8f9f9;
  padding: 5px;
  max-width: 150px;
  .payment-amount {
    margin-left: 8px;
    span {
      display: block;
      margin: 0;
      font-size: 12px;
    }
  }
}

.payment-label {
  background-color: #1bc47d;
  color: #fff;
  padding: 4px 6px;
  font-size: 12px;
  border-radius: 5px;
}
