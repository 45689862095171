.text-askReview{
  cursor: pointer;
  color: blue !important;
}

.review-comment {
  font-size : 14px !important;
}

.review-time {
  display: inline-flex;
  align-items: center;
  text-align: right;
  color: gray;
  font-size : 12px !important;
}

.review-items {
  margin-top: 15px;
}