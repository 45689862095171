.workflows-page {
  padding: 20px;
  .page-name-container {
    display: flex;
    justify-content: flex-start;
    .workflow-name {
      margin-left: 5px;
    }
  }
  .page-table-cell {
    padding-left: 0 !important;
  }
  .header-container {
    margin-bottom: 1rem;
    .page-name {
      font-size: 24px;
      color: #0D172A;
    }
  }
  
  .page-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .search-and-filter {
      display: flex;
      width: 30%;
    }
    i.zmdi-plus {
      margin-right: 10px;
    }
  }
  .page-table-cell {
    font-weight: normal;
    padding-left: 0.3125rem;
  }
  .table-pagination-container{
    display: flex;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 375px) {
  .workflows-page {
    padding: 0;
    
    .page-toolbar {
      .search-and-filter {
        width: 80%;
      }

      .search-input-wrapper {
        width: 100%;
        
        &>div {
          width: 100%;
        }
      }

      .btn-custom {
        width: 14%;
        margin-right: 5px;
        span {
          display: none;
        }
      }
    }
  }
}
