.button {
  //font-family: 'SF Pro Display';

  &.fluid {
    width: 100%;
  }

  .icon-wrapper {
    display: flex;
    flex: 0 1;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
  }
}