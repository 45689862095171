.timepicker-wrapper {
  max-width: fit-content;
}

.timepicker {
  display: flex;
  align-items: center;
  gap: 10px;
}

.timepicker-input {
  border-width: 1px;
  border-style: solid;
  border-color: #D8DADD;
  border-radius: 4px;
  padding: 5px;

  &.error {
    border-color: #FF4545
  }
}

.error-message {
  display: flex;
  flex: 1 1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #FF4545;
  max-width: inherit;
}


input[type=time] {
  color: #0D172A;
  font-size: 12px;
  min-width: 135px;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as
the up and down buttons and the 'X' button */
input[type=time]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and
second, second and am/pm */
input[type=time]::-webkit-datetime-edit-text {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type=time]::-webkit-datetime-edit-hour-field {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 6px;

  &:focus {
    background-color: #FAA61A;
    color: #fff;
  }
}

/* Minute */
input[type=time]::-webkit-datetime-edit-minute-field {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 2px;

  &:focus {
    background-color: #FAA61A;
    color: #fff;
  }
}

/* AM/PM */
input[type=time]::-webkit-datetime-edit-ampm-field {
  padding: 5px;
}

/* 'X' button for resetting/clearing time */
input[type=time]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type=time]::-webkit-inner-spin-button {
  display: none;
}
