.select-wrapper {
  display: flex;
  flex-direction: column;
  .settings-select {
    position: unset;
    height: 35px;
  }
  .settings-select-label {
    font-size: 15px;
    font-weight: 600;
  }

  .settings-form-control {
    width: 330px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
