.launcher-icon-container{
  .icons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    >div{
      width: 48px;
      height: 48px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      &.selected {
        border: 1px solid rgba($color: #d8392f, $alpha: 1);
        border-radius: 4px;
       span{
        color: rgba(13, 23, 42, 1);
       }
      }
      span{
        color: rgba(13, 23, 42, 0.4);
      }
    }
  }
}