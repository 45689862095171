.launcher-position-mobile-container {
  .launcher-positions {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .launcher-container {
      width: 60px;
      height: 80px;
      border: 1px solid #d8dadd;
      border-radius: 4px;
      margin-right: 5px;
      margin-bottom: 5px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: rgba(13, 23, 42, 0.4);
        border-radius: 100%;
      }
      &.selected {
        border: 1px solid #ff7a59;
        &::after {
          background: #ff7a59;
        }
      }
      &.launcher-position-mobile-bottom-right {
        &::after {
          bottom: 5%;
          right: 5%;
        }
      }
      &.launcher-position-mobile-bottom-center {
        &::after {
          bottom: 5%;
          right: calc(50% - 12px);
        }
      }
      &.launcher-position-mobile-bottom-left {
        &::after {
          bottom: 5%;
          left: 5%;
        }
      }
      &.launcher-position-mobile-top-left {
        &::after {
          top: 5%;
          left: 5%;
        }
      }
      &.launcher-position-mobile-top-right {
        &::after {
          top: 5%;
          right: 5%;
        }
      }
    }
  }
}
