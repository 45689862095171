.available-dot{
  background-color: #1BC47D;
  border-radius: 25px;
  height: 14px;
  width: 14px;
  display: block;
  position: relative;
  border: 3px solid white;
  margin-right: 10px;
}

.call-title {
  color: #1BC47D;
}

.controls-wrapper {
  border-bottom: 1px solid #ECECEE;
  padding: 1.625rem 1.25rem;
  font-weight: 500;
}

.call-controls {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.start-call-button {
  background: #FF7A59;
  color: white;
  height: 35px;
  width: 85%;
  padding: 0;
  font-size: 15px;
  border: none;
  display: block;
  border-radius: 4px;
  margin-right: 10px;
  max-width: 350px;
  &.disabled {
    pointer-events: none;
    background: #fcb7a8;
  }
  &:hover {
    background: #ff9779;
    color: white;
  }
}

.camera-button {
  background: transparent;
  border-radius: 4px;
  border: 1px solid black;
  width: 35px;
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  &.disabled {
    pointer-events: none;
    background: #a5a5a5;
    color: #ececec;
  }
  &:hover {
    background: #e9e9e9;
    color: white;
  }
}

.microphone-icon{
  margin-right: 10px;
  font-size: 20px;
}
.camera-icon {
  color: black;
  margin: 0 auto;
  font-size: 20px;
}
