.error-boundary-wrapper {
  height: 100vh;
  width: 100%;
  flex-direction: row;

  .error-content {
    color: #253342;
    margin-left: 5em;
    flex-direction: column;
    h2 {
      font-size: 8em;
      width: 100%;
    }
    h3 {
      font-size: 3.5em;
      width: 100%;
    }

    .sentiment-icon {
      font-size: 4em;
    }

    .link-wrapper {
      font-size: 0.6em;
      padding: 0;
      width: 100%;
      .err-link {
        display: inline-block;
        padding: 0;
      }
    }
  }
}
