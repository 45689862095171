.color-picker-container {
  position: relative;
  .color-label {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #d8dadd;
  }
  .color-input-container {
    border: 1px solid #d8dadd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 0.5rem;
    >.preview{
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
    .color-input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
}
