.text-red {
  color: red;
}

.ant-choices-item {
  display: flex;
  flex-direction: column;
}

.items-container {
  display: flex;
  width: 100%;
}

.error-msg {
  color: #ff3739;
  font-size: 14px;
  width: 100%;
}

.error-border {
  border: 1px solid #ff3739;
}

.add-question-modal-container {
  .add-question-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 70px;
      > span {
        margin-right: 10px;
      }
    }
    label {
      margin: 0;
    }
  }
  .preChat-container {
    > div {
      width: 47% !important;
    }
  }
  .radio-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div {
      width: 32%;
      border: 1px solid #d8dadd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      &.selected {
        border-color: #1bc47d;
        color: #1bc47d;
      }
      > label {
        width: 90%;
        margin: 0;
        text-transform: capitalize;
        > input {
          margin-right: 10px;
          margin-left: 0px;
        }
        input[type="radio"]:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }

        input[type="radio"]:checked:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: #1bc47d;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }
      }
    }
  }
  .add-icon-label {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ant-modal textarea.form-control {
    margin-bottom: 0.5rem;
  }
}
