.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-box {
  width: 456px;
  border-radius: 4px;
  background-color: #fff;
  padding: 24px;
}

.drop-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border: 1px dashed #D8DADD;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  height: 155px;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 16px;

    span {
      color: #FF7A59;
      cursor: pointer;
    }
  }
}

.drop-box-progress-container {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;

  .loading {
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }
}

.drop-box-progress-header {
  position: relative;

  .title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }

  .progress-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      margin-left: 4px;
    }
  }
}

.drop-box-progress-body {
  .left-time {
    margin-bottom: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

  }
}
