.ant-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #FFFFFF;
  border: 1px solid #D8DADD;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  .ant-card-header {
    padding: 12px 60px 4px 57px;
    position: relative;

    &.drag {
      padding: 12px 60px 4px 80px;
    }

    .drag-mark {
      position: absolute;
      left: 12px;
      cursor: move;
      opacity: 0.64;
    }

    .switch-wrapper {
      position: absolute;
      left: 16px;

      &.drag {
        left: 40px;
      }
    }

    .label {
      display: flex;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #0D172A;
      opacity: 0.8;
    }

    .title {
      display: flex;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #0D172A;
    }

    .description {
      display: flex;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 0;
      color: #0D172A;
      opacity: 0.8;
    }

    .action-btn-wrapper {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .ant-card-body {
    padding: 4px 60px 12px 80px;
  }

  .edit-button {
    border-radius: 4px;
    padding: 2px;
  }

  .active {
    background: #1BC47D;
    color: white;

    &:hover {
      background: #1BC47D;
      color: white;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .ant-card {
    .ant-card-header {
      &.drag {
        padding: 12px 60px 4px 55px;
      }
    }
    .ant-card-body {
      padding: 4px 10px 12px 55px;
      .MuiFormControlLabel-root {
        margin-left: 0 !important;
      }
    }

    .drag-mark {
      display: none;
    }

    .switch-wrapper {
      &.drag {
        left: 10px !important;
      }
    }
  }
}
