.action-btn-group {
  display: flex;
  justify-content: flex-end;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #0D172A;
  background: #FFFFFF;
  border: 1px solid #253342;
  box-sizing: border-box;
  border-radius: 4px;
  i{
    margin-right: 11px;
  }
  span{
    margin-right: 5px;
  }
  & + & {
    margin-left: 15px;
  }

  &:hover {
    background: #D8DADD;
    color: #0D172A;
    border-color: gainsboro;
  }
}
