.chat-style-preview-container {
  .chat-style-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 5px;
    .chat-style-preview-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #3a1c1c;
      width: 100%;
      padding: 10px;
      transition-duration: 0.3s;
      .logo {
        background: #D8392F;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-right: 10px;
        font-family: "SF Pro Display";
        font-weight: bold;
        color: #ffffff;
        transition-duration: 0.3s;
      }
      .logo-img{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin-right: 5px;
      }
      .chat-title {
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        .sub {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    .section {
      background: #f8f9f9;
      padding: 10px;
      width: 100%;
      transition-duration: 0.3s;
      .chat {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        &.mine {
          float: right;
          .message {
            background-color: #d8dadd;
          }
          img {
            display: none;
          }
          span {
            width: 100%;
            &.date {
              text-align: right;
            }
          }
        }
        img {
          width: 32px;
          margin-right: 10px;
          border-radius: 32px;
        }
        .message {
          background: #fff;
          padding: 8px;
          font-size: 12px;
          border-radius: 4px;
        }
        span {
          width: 90%;
          display: block;
          &.date {
            font-size: 10px;
            margin-top: 2.5px;
            svg {
              font-size: 10px;
              fill: #d8392f;
              margin-left: 2.5px;
            }
          }
        }
      }
    }
    .footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 15px;
      background: #253342;
      transition-duration: 0.3s;
      .icon{
        padding: 10px;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          font-size: 14px;
          fill: #fff;
        }
      }
      .on-going-call{
        font-size: 12px;
        text-align: left;
        flex: 1;
        margin-left: 5px;
        color: rgba(255, 255, 255, 0.64);
        :first-child{
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .call-answer-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background: #1BC47D;
        border-radius: 4px;
        font-size: 12px;
        padding: 3px 13px;
        color: #fff;
        svg{
          font-size: 12px;
          margin-right: 4px;
          fill: #fff;
        }
      }
    }
  }
}
