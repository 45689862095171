.local-video {
  position: absolute;
  top: 0;
  right: 0;
  width: 17%;
  z-index: 2;
  &.isDisabled {
    display: none;
  }
}
.share-my-screen {
  position: fixed;
  height: 90vh;
  width: 100%;
  background: rgba(32, 33, 36, 0.89);
  z-index: 1;
}

.remote-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &.isDisabled {
    display: none;
  }
}

.screen-share {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.video-wrapper {
  position: relative;
  height: 90vh;
}
.video-container {
  width: 100%;
  position: relative;

  &.with-chat {
    top: 0;
    position: absolute;
    grid-template-columns: 75vw 25vw;
    display: grid;
    .remote-video {
      min-width: 100%;
    }
    .local-video {
      display: none;
    }
  }

  .avatar-container {
    position: absolute;
    left: 46%;
    top: 35%;
    z-index: 1;

    .avatar-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 2px solid grey;
    }

    .avatar-abbreviation {
      width: 150px;
      height: 150px;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      background-color: #dadada;
      border-radius: 50%;
      font-size: 60px;
      margin-left: 150px;
      margin-top: 150px;
    }
  }

  .replacement-video {
    visibility: hidden;
    position: absolute;
    top: 40%;
    left: 45%;
    width: 200px;
    height: 200px;
    background-color: #d4556d;
    border-radius: 50%;
    border: 3px solid white;

    .abbreviation {
      font-weight: bold;
      font-size: 4em;
    }
  }
}

.video-call-wrapper {
  width: 100%;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .video-container {
    &.with-chat {
      grid-template-columns: auto;
    }
  }
}
