.settings-page {

  .settings-left-sidebar {

    height: 100vh;

    .settings-general {

    }

    .settings-chat {

    }
  }


  .settings-main-content {
     background-color: white;
  }
}

.rct-page-content {
  padding: 0;
}

.inactive-questions-container{
  padding-bottom: 50px;
}

@media only screen and (max-width: 375px)  {
  .settings-left-sidebar{
    display: none;
  }
  .settings-main-content{
    width: 100% !important;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .settings-left-sidebar{
    display: block;
    width: 100% !important;
    .active-link {
      background-color: transparent;
    }
  }

  // Temporary return back title section as we have store selection there
  /*.settings-page-title {
    display: none !important;
  }*/
  .settings-main-content {
    display: none;
  }
  .inactive-menu {
    .settings-left-sidebar{
      display: none;
    }
    .settings-main-content {
      display: block;
      width: 100% !important;
    }
  }

  .settings-page {
    height: 100%;
  }
  .appearance-description {
    padding-bottom: 12px;
  }
  .rct-page-content {
    height: 100%;
  }

}
// Temporary return back title section as we have store selection there
/*@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .settings-page-title {
    display: flex !important;
  }
}*/

