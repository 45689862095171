.payment-table-container {
  table {
    width: 100%;
    thead {
      background-color: transparent;
    }
    th {
      display: table-cell !important;
      font-weight: normal;
      font-size: 0.9rem;
    }
    td {
      .input-wrapper {
        margin-bottom: 0;
      }
      .product-end-adornment {
        i {
          margin-left: 5px;
          font-size: 16px;
        }
      }
    }
    .expend-column {
      width: 40%;
    }
    .delete-btn {
      justify-content: center;
      align-items: center;
      i {
        font-size: 16px;
      }
    }
  }
}
