.chat-wrapper {
  width: 75%;
  .chat-bubble-wrap {
    margin-right: 10px;
    max-width: 80%;
    .chat-bubble-timer-container{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .chat-bubble-type{
        background: #D8DADD;
        font-size: 10px;
        padding: 1px 5px;
        border-radius: 4px;
        i{
          margin-right: 5px;
        }
      }
      .time{
        display: flex;
        justify-content: space-between;
        align-items: center;
        i{
          color: #D8392F;
        }
      }
    }
  }
  .chat-main-body {
    .chat-head {
      padding: 0.625rem 1.5rem;
      @media(max-width:450px){
        padding:0.75rem;
        .video-icon{
          display: none;
        }
      }
      border: 1px solid #ECECEE;
      background-color: white;
      .chat-head-controls {
        display: flex;
        justify-content: flex-end;
        .assign-conversation-button {
          display: flex;
          justify-content: space-between;
          background-color: #FF7A59;
          width: 135px;
          color: white;
          padding: 10px;
          font-size: 15px;
          &.disabled {
            color: black;
            pointer-events: none;
            background-color: #D8DADD;
            width: 115px;
          }
        }
        .resolve-conversation-button {
          display: flex;
          justify-content: space-between;
          background-color: #1BC47D;
          color: white;
          padding: 10px;
          font-size: 15px;
          width: 95px;
          margin-left: 15px;
          margin-right: 10px;
          &.disabled {
            background-color: unset;
            pointer-events: none;
            border: 1px solid #606060;
            color: #606060;
          }
          &.resolved {
            width: 115px;
          }
        }
      }
      button {
        height: 2.25rem;
        width: 2.25rem;
        font-size: 1.25rem;
        margin: 0 0.25rem;
      }
    }
    .chat-body {
      margin-top: 15px;
      margin-left: 10px;
      .chat-bubble {
        padding: 0.625rem;
        box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
        display: flex;
        align-items: center;
        position: relative;
        overflow-wrap: anywhere;
        min-width: 50px;
        p {
          white-space: pre-line;
        }
        &.even {
          background-color: white;
          border-radius: 4px;
        }
        &.odd {
          border-radius: 4px;
          background-color: #d8dadd;
          margin-right: 0px;
          margin-left: auto;
        }

        .type-media {
          position: relative;
          max-width: 200px;
          max-height: 150px;
          display: inline-block;
          overflow: hidden;
          vertical-align: top;

          .type-media-video {
            position: relative;
            video {
              width: 200px;
              height: 125px;
            }
          }
          img {
            max-width: 190px;
            max-height: 140px;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        .download-link {
          position: absolute;
          top: 40%;
          margin-left: 15px;

          span {
            font-size: 25px;
            color: lightseagreen;
          }
        }
      }
      .type-doc {
        padding-top: 10px;
        a {
          color: $blue-1;
          white-space: nowrap;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .type-payment{
        display: block
      }

      .survey-header {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        background-color: #F3F3F3 !important;
        box-shadow: none;
      }
      .survey-container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin-bottom: 1rem;
      }
      .survey-icon-block {
        background-color: #D83A2F;
      }
      .survey-icon {
        font-size: 25px;
        color: #fff;
      }
      .survey-item {
        border-radius: 0 !important;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      .survey-wrapper {
        width: 50%;
      }
      .chat-bubble.even.bg-aqua.survey-item:last-child {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
      .chat-bubble.even.bg-aqua.survey-item:not(:last-child) {
        margin-bottom: 2px;
      }
      .survey-title {
        font-weight: 700;
        width: 100%;
      }
      .emoji-wrapper {
        margin-top: 15px;
        .disabled-button {
          color: rgba(16, 16, 16, 0.3);
          border-color: rgba(16, 16, 16, 0.3);
        }
        .emoji-button {
          border-width: 1px;
          width: 19%;
          margin-right: 1%;
          height: 35px;
          border-radius: 7px;
          .emoji-item {
            font-size: 24px;
          }
        }
      }
    }

    .chat-footer {
      width: 100%;
      bottom: -45px;
      .sender-controls {
        min-width: 50px;

        &:hover {
          cursor: pointer;
        }
      }
      .msg-form {
        width: 85%;
        position: relative;
        &.with-payment {
          textarea {
            padding: 6px 0px 0px 15px;
            height: 30px;
          }
        }
      }
      .msg-input {
        border: 0;
        padding: 7px 10px 55px 15px;
        box-shadow: inset 0 0 0 #ddd;
        width: 95%;
        &:focus {
          box-shadow: inset 0 0 0 #ddd;
          //border: 0;
        }
      }
    }
  }
  .chat-content {
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .chat-box-main {
      position: absolute;
      top: 0;
      bottom: 10rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .call-ended-message {
    margin: 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 140px;
    &.call-declined {
      width: 115px;
    }
    span {
      font-size: 15px;
    }
  }
}
.disabledSenderIcon{
  pointer-events: none;
  color: #bcbcbc;
}
.chat-sidebar {
  display: flex;
  .users-list-wrapper {
    width: 300px;
  }
  .user-wrap {
    height: auto;
  }
  .search-wrapper {
    .search-icon {
      position: absolute;
      right: 1rem;
      top: 0.9rem;
    }
  }
  .chat-list {
    ul {
      padding-bottom: 70px !important;
      .user-list-item {
        color:$gray-900;
        cursor: pointer;
        padding: 1rem;
        border-bottom: 2px solid $grey-2;
        transition: all 0.3s ease-in-out;

        .status-active-customer {
          position: absolute;
          top: 70%;
          left: 70%;
          padding: 4px;
        }

        .media-left {
          .badge {
            position: absolute;
            bottom: 0px;
          }
        }
        .msg-count {
          .badge {
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
          }
        }
        &.item-active {
          background-color: #f8f4ee;
          border-left: 3px solid $orange-1;
        }
      }
      &.last-user {
        padding-bottom: 100px;
      }
    }
  }
}

.incoming-call-chat {
  .msg-form {
    width: 35%;
    input {
      width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .chat-bubble-wrap {
    max-width: 290px;
    overflow: hidden;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .chat-head {
    .chat-head-controls {
      justify-content: flex-start !important;
      .right-button {
        position: absolute;
        right: 10px;
      }
    }
  }
  .chat-footer {
    .msg-input {
      width: 84%!important;
    }
    .submit-button {
      position: absolute;
      height: 20px;
      top: 10px;
      right: 20px;
      color: #1BC47D;
    }
    .mobile-channels-box{
      display: flex;
      flex-direction: row;
    }
    .btn-in-use {
      color: rgb(93, 146, 244) !important;
    }
  }
}

@media only screen and (max-width: 375px) {
  .chat-bubble{
    max-width: 300px!important;
  }
}
