.request-payment-modal-container {
  div .label {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
  }
  .label::after{
    content:" *";
    color: red;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .itemTitle{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
  }
}
