.paymentsTable {
  padding: 20px;
  .page-table-cell {
    padding-left: 0!important;
  }
  .expoImg{
    vertical-align: top;
  }
  .settings-page-title{
    display: flex;
    flex-direction: column;

    .title{
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 0.25rem;
    }

    .page-name{
      font-size: 24px;
      color: #0D172A;
      margin-bottom: 1rem;
    }
  }

  .page-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .search-and-filter{
      display: flex;
      width: 30%;
    }
  }

  .table-responsive{
    margin-bottom: 1.125rem;
  }

  .table-pagination{
    display: flex;
    justify-content: flex-end;
  }

  .agents-table {
    border: 5px;
  }

  .page-table-cell{
    font-weight: normal;
    padding-left: 0.3125rem;
  }

  .table-pagination-container{
    display: flex;
    justify-content: flex-end;
  }
}
.store-select-title {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 8px;
  span {
    margin-left: 5px;
  }
}
.store-select-error-text{
  font-size: 14px;
  color: red;
  margin-left: 5px;
}

.reinvite-agent-btn{
  line-height: unset!important;
  padding: 8px 0!important;
  .reinvite-button-text {
    width: 100px;
  }
  svg{
    margin-left: 5px;
  }
}

@media only screen and (max-width: 375px)  {
  .agents-page {
    padding: 0;

    .page-toolbar {
      .search-and-filter {
        width: 80%;
      }

      .search-input-wrapper{
        width: 100%;

        &>div{
          width: 100%;
        }
      }

      .btn-custom {
        width: 14%;
        margin-right: 5px;
        span{
          display: none;
        }
      }
    }

    .action-btn{
      i{
        margin: 0;
      }
      span{
        display: none;
      }
    }

    table{
      height: 400px;
      .MuiTableCell-body{
        padding: 12px 0;

        &:nth-child(2), &:nth-child(3), &:nth-child(4){
          display: none;
        }
        &:nth-child(1){
          max-width: 200px;
        }
        &:nth-child(5){
          max-width: 54px;
        }
      }
    }
  }

  .agents-table-head{
    display: none !important;
  }

  .action-btn-group{
    justify-content: center;
  }

  .page-table-cell{
    button {
      display: flex;
      justify-content: center;
      width: 39px;
      height: 39px;
    }
  }

  .sm-center{
    justify-content: center;
  }

  .agents-table-cell{
    padding: 0 !important;
    border-left: 0 !important;
  }

}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .agents-page {
    table {
      height: auto;
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .agents-page {
    padding-left: 10px;
    .search-and-filter{
      .search-and-filter{
        width: 95%;
      }
    }
    .page-toolbar {
      padding-top: 1.5rem;
      .btn-custom {
        width: auto;
        margin-right: 10px;
        background-color: #FF7A59;
        border-color: #FF7A59;
        font-size: 17px;
      }
    }
    .page-table-cell {
      padding-left: 0;
    }
  }
}
