.user-dropdown-menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  min-width: 215px;
  background-color: #fff;
  color: #0D172A;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding-top: 60px;
}

.user-dropdown-menu-status {
  .user-dropdown-menu-status-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #0D172A;
    display: block;
    padding: 0 28px;
    margin-bottom: 10px;
  }

  .status-list {
    margin-top: 10px;
    list-style: none;
    padding: 0;
  }

  .status-item {
    &.active {
      background-color: #33475B;
      box-shadow: inset 1px 0 0 #FF7A59;

      .title {
        color: #FFFFFF
      }
    }

    .status {
      $size: 8px;
      $borderSize: 2px;
      width: $size;
      height: $size;
      border: $borderSize solid #ffffff;
      border-radius: 50%;
      box-sizing: content-box;
      position: absolute;
      left: 58px;
      top: 50%;
      transform: translateY(calc(-#{$size + $borderSize} / 2));

      &.green {
        background-color: #1BC47D;
      }

      &.yellow {
        background-color: #FAA61A;
      }

      &.gray {
        background-color: #646b77;
      }
    }

    .title {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      color: #0D172A;
    }
  }

  .status-item-container {
    padding: 0 35px 0 85px;
    cursor: pointer;
    position: relative;
  }
}

.user-dropdown-menu-logout {
  padding: 25px 30px;
}

@media (max-width: 375px) {
  .user-dropdown-menu{
   min-width: 120px;

  .user-dropdown-menu-status{
      .status-item-container{
        padding-left: 10px;
      }
    }

    .user-dropdown-menu-logout{
      padding: 0 0 0 10px;
    }
  }

  .navbar-right li a button, .navbar-right li button{
    width: 70px;
  }
  
  .status-item-container{
    .status {
      left: 12px!important;
    }
  }
}
