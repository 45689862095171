.status-dot {
  border-radius: 25px;
  position: absolute;
  height: 14px;
  width: 14px;
  display: block;
  margin-top: 25px;
  margin-left: 25px;
  border: 3px solid white;

  &.sm {
    height: 32px;
    width: 32px;
  }

  &.md {
    height: 17px;
    width: 17px;
    margin-left: 30px;
    margin-top: 30px;
  }

  &.available {
    background-color: #1BC47D;
  }

  &.busy {
    background-color: #FAA61A;
  }

  &.offline{
    background-color: #646b77;

  }
}

.avatar {
  background-color: #D8DADD;
  border-radius: 25px;
  position: absolute;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.sm {
    height: 32px;
    width: 32px;
  }

  &.md {
    width: 45px;
    height: 45px;
  }
  &.lg {
    width: 95px;
    height: 95px;
    border-radius: 50px;
  }
  &.mb {
    width: 64px;
    height: 64px;
    border-radius: 50px;
  }

  .status {
    $size: 8px;
    $borderSize: 2px;
    width: $size;
    height: $size;
    border: $borderSize solid #ffffff;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    bottom: 0;
    right: 0;

    &.available {
      background-color: #1BC47D;
    }

    &.busy {
      background-color: #FAA61A;
    }

    &.offline {
      background-color: #646b77;
    }
  }
}

.avatar-block {
  width: 45px;
  height: 35px;
  margin-right: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  font-weight: 500;

  &.sm {
    height: 32px;
    width: 32px;
  }

  &.md {
    width: 45px;
    height: 45px;
  }
  &.lg {
    width: 95px;
    height: 95px;
  }
  &.mb {
    width: 64px;
    height: 64px;
  }
}

.user-abbreviation {
  font-size: 13px;
  position: absolute;

  &.md {
    font-size: 15px;
  }
  &.lg {
    font-size: 20px;
  }
  &.mb {
    font-size: 20px;
  }
}

.avatar-img {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  object-fit: cover;

  &.sm {
    height: 32px;
    width: 32px;
  }

  &.md {
    height: 45px;
    width: 45px;
  }
  &.lg {
    width: 95px;
    height: 95px;
    border-radius: 50px;
  }
  &.mb {
    width: 64px;
    height: 64px;
    border-radius: 50px;
  }
}
