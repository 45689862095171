.collapsed-container{
  padding: 8px;
}

.history-container{
  display: flex;
  flex-direction: column;
  padding: 8px 10px;

  .page{
    display: flex;
    //max-width: 230px;
    position: relative;
    overflow: hidden;
    .history-item-wrapper {
      a {
        max-width: 214px;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .spend-time {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .icon{
    margin-top: 5px;
    margin-right: 10px;
  }
}
