.company-logo-container {
  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      background: red;
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-right: 10px;
      font-family: "SF Pro Display";
      font-weight: bold;
      color: #ffffff;
      font-size: 32px;
      background-size: cover;
      background-position: center;
    }
    .controls {
      span {
        color: #0d172a;
        font-size: 12px;
      }
      .upload-btn {
        color: #017aff;
        svg {
          fill: #017aff;
        }
      }
      .remove-btn {
        color: #ff4545;
        svg {
          fill: #ff4545;
        }
      }
    }
  }
}
