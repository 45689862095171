.user-info-wrapper {
  width: 25%;
  border: 1px solid #ECECEE;
  background-color: white;
  font-size: 14px;
}

.user-name {
  display: flex;
  align-items: center;
}

.store {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.name-wrapper {
  border-bottom: 1px solid #ECECEE;
  padding: 1.625rem 1.25rem;
  font-weight: 500;
}

.call-title{
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.email {
  font-weight: 400;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 959px) {
  .user-info-wrapper {
    width: 100%;
    height: 100% !important;
  }
}

@media only screen and (min-device-width : 960px) and (max-device-width : 1280px) {
  .user-info-wrapper {
    width: 25%;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .user-info-wrapper {
    overflow-y: hidden;
  }
}
