.menu-item {
  .item-title {
    font-size: 14px;
    font-weight: 500;
  }
  .right-arrow {
    display: none;
  }

  .item-description {
    font-size: 12px;
    font-weight: 400;
  }
}

.item-link {
  color: #515152;
}

.active-link {
  background-color: white;
  box-shadow: #ff7a59 2px 0 0 inset;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .menu-item {
    position: relative;
    .right-arrow {
      display: block;
      position: absolute;
      right: 15px;
      padding-top: 10px;
    }
  }
}
