.launcher-size-container {
  .launcher-sizes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 120px;
      height: 128px;
      border: 1px solid #d8dadd;
      border-radius: 4px;
      margin-right: 10px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      margin-bottom: 5px;
      &.selected {
        border: 1px solid #d8392f;
      }
      span {
        font-size: 12px;
      }
      > div {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .launcher {
        background: linear-gradient(
          186.37deg,
          rgba(239, 239, 239, 0.4) 10.04%,
          rgba(177, 177, 177, 0.4) 89.96%
        );
        border: 1px solid #d8dadd;
        border-radius: 100%;
      }
      &.launcher-size-sm {
        .launcher {
          width: 48px;
          height: 48px;
        }
      }
      &.launcher-size-md {
        .launcher {
          width: 56px;
          height: 56px;
        }
      }
      &.launcher-size-lg {
        .launcher {
          width: 64px;
          height: 64px;
        }
      }
      &.launcher-size-xl {
        .launcher {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
