.store-select-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;

  .store-select {
    width: 50%;

    >div{
      height: 40px;
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .store-select-container{
    justify-content: left;
  }
}
