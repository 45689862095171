body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.card {
  max-width: 512px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #D8DADD;
  box-sizing: border-box;
  border-radius: 8 px;
}

.bi-arrow-left{
  vertical-align: middle;
}

.payment_container{
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-end {
  text-align: right!important;
}

.payment_button {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  width:100%;
}

.card-header, .pcard-body, .card-footer {
  background-color: #FFFFFF;
  padding: 16px;
}

.payment_avatar {
  vertical-align: middle;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.text-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #0D172A;
}

.text-content-highlight {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #0D172A;
}

.text-content {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0D172A;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(13, 23, 42, 0.64);
}

p {
  margin: 0px;
}

hr.hr-no-margin {
  margin: 0px;
}

.back-button {
  background-color: transparent;
  border-color: transparent;
  width: auto;
  padding: 0;
}

#pt_hpf_form {
  height: 300px;
}

#pt_hpf_form div:first-child {
  height: 100%;
}

#payment-card-check, #payment-failure, #ach-card-check{
  width: 100%;
}

.payment-avatar {
  vertical-align: middle;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.btn-failure {
  width: 100%;
}
