.settings-modal-body {
  display: flex;
  height: 65vh;
  width: 70vw;
  max-width: 700px;
  max-height: 500px;
  min-height: 350px;
  .left-side {
    background-color: #253342;
    width: 35%;
    display: flex;
    flex-direction: column;
    h2 {
      color: white;
      height: 55px;
      padding: 20px;
      text-align: left;
    }
  }

  .right-side {
    width: 65%;
  }
  .button {
    border-radius: 0;
    color: #D3D6D9;
    height: 55px;
    font-size: 16px;
    span {
      width: 100%;
      text-align: left;
      margin-left: 15px;
      display: block;
    }
    i {
      margin-right: 10px;
    }
  }
  .active {
    background-color: #33475B;
    border-left: 1px solid #FF7A59;
    color: white;
    i {
      color: #FF7A59;
    }
  }
  .audio {
    display: flex;
    padding: 15px;
    flex-direction: column;

    .settings-block {
      display: flex;
      margin-bottom: 20px;

      .control {
        display: flex;
        width: 20%;
        border-radius: 15px;
        align-items: center;
        margin-top: 10px;
        position: relative;

        .sound-test-button {
          font-size: 15px;
          margin-left: 5px;
          height: 35px;
          padding-left: 5px;
          border-radius: 5px;
          .button-text {
            padding-left: 10px;
          }
          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
        .sound-icon{
          margin-right: 5px;
          margin-left: 3px;
        }
        .microphone-icon {
          margin-right: 10px;
          margin-left: 5px;
        }
        .volume-wrapper {
          height: 5px;
          background-color: #d9d9d9;
          width: 150px!important;
          border-radius: 15px;
          .volume {
            height: 5px;
            background-color: #FF7A59;
            max-width: 55px;
            border-radius: 15px;
          }
        }
    }
  }
  .video-block {
    display: flex;
    padding: 15px;
    flex-direction: column;
  }
  video {
    width: 160px;
    height: 120px;
    border-radius: 6px;
    margin-top: 15px;
    background-color: black;
  }
  .settings {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  &.settings-modal-body-mobile {
    display: block;
    max-width: inherit;
    width: 100%;

    .left-side {
      display: block;
      width: 100%;
      height: 95px;
      overflow: hidden;
      background-color: #33475B;

      h2 {
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        margin: 0;
        padding: 10px;
        height: auto;
      }

      button {
        border: 0;
      }
    }
    .right-side {
      display: block;
      width: 100%;
      background: #253342;
      color: #d2d2d2;
      position: fixed;
      height: calc(100% - 95px);
      left: 0; 
      top: 95px;
      overflow-y: scroll;
    }

    .select-wrapper {
      width: 100%;
      max-width: unset;

      .settings-select {
        border: 1px solid #d2d2d2;
        color: white;
      }

      .settings-form-control {
        width: 100%;
        margin: 10px 0 0 0;
      }
    }

    .settings-block {
      position: relative;

      .control {
        position: absolute!important;
        right: 5px;
        top: -2px;
        margin: 0;

        button {
          margin: 0!important;
          color: #d2d2d2;
        }
      }
    }

    video {
      width: 100%;
      margin: 0;
      height: auto;
      object-fit: cover;
    }
  }
}
