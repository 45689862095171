.incoming-call-chat-header {
    border-radius: 0 !important;
}
.incoming-call-chat {
    background-color: white;
    display: grid;
    grid-template-rows: 8vh 3vh 8vh 71vh;
    height: 90vh;
    .rcw-messages-container {
        max-height: none;
        height: auto;
    }
    .chat-bubble{
        color: #3c3c3c!important;
        background-color: #eaeaea !important;
        padding: 10px;
        border-radius: 5px;
        margin-right: 10px;
        img {
            max-width: 190px;
            max-height: 140px;
            border-radius: 5px;
            object-fit: cover;
        }
        p, span {
            white-space: pre-line;
        }
    }
    .call-time-wrap{
        background-color: #253342;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        .phone-icon{
            background-color: #515C68;
            border-radius: 25px;
            width: 40px;
            height: 40px;
            padding: 9px;;
        }
        .call-time {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            font-size: 15px;
            color: #d9d9d9;
        }
    }
    .chat-main-body {
        position: relative;
        background-color: #F8F9F9;
        .chat-body {
            margin: 10px -35px 0 12px;
            position: absolute;
            overflow-y: scroll;
            padding: 10px;
            width: 25vw;
            background-color: #F8F9F9;
            height: 65vh;
            .type-doc {
                margin-top: 5px;
                margin-right: 10px;
            }
        }
        .chat-footer {
            position: absolute;
            bottom: 0;
            height: 65px;
            display: flex;
            align-items: center;
            border-top: 2px solid #e9e9e9;
            background-color: white;
            .msg-form {
                width: 95%;
                height: 30px;
                .msg-input {
                    width: 100%;
                    height: 30px!important;
                    resize: none;
                    border: none;
                }
            }

            .sender-wrap {
                width: 25vw;
                height: 8vh;
                display: flex;
                align-items: center;
                input {
                    border: none;
                    box-shadow: none;
                }
            }
            .sender-controls {
                width: 60px;
                padding-top: 0!important;
            }
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 13px 0;
    background-color: #3A1C1C;
    color: white;
    font-size: 15px;
    align-items: center;
    width: 25vw;
    .header-title-wrap {
        margin: 0 auto;
    }
}

.store-location {
    font-size: 13px;
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    margin-top: -7px;
    padding-left: 5px;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .header {
    width: 100%;
  }
  .incoming-call-chat {
    .chat-main-body {
      .chat-body {
        .flex-nowrap:last-child {
          margin-bottom: 100px !important;
        }
        width: 100%;
      }
      .chat-footer {
        width: 100%;
        .sender-wrap {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
