.info-request-popover-container {
  display: block;
  background-color: #fff;
  padding: 10px;
  max-width: 300px;

  .icon-lg {
    font-size: 32px;
  }

  .icon-sm {
    font-size: 16px;
    margin-right: 5px;
  }
  
  .icon-cl{
    font-size: 16px;
    margin-top: -10px;
  }

  p {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  span { font-size: 0.8rem; }

  .text-primary:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
