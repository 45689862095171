.create-profile {
  background-color: #fff;
  width: 816px;
  border-radius: 8px;
  padding: 24px 150px 57px;

  &-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #F8F9F9;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  &-user-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #0D172A;
  }

  &-footer {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}

.profile-photo-wrapper {
  margin-top: 40px;
  margin-bottom: 16px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .create-profile {
    width: 100%;
    padding: 24px 10px;
    height: 100%;
  }
}
