.modal-container{
  padding: 24px;
  background: white;

  .modal-header{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0 0 0;
    margin-bottom: 10px;
    border-bottom: none;

    .modal-title{
      margin-right: 126px;
    }

    i{
      margin-right: 8px;
    }
  }

  .modal-main{
    .modal-input{
      width: 100%;
    }

    .phone-picker{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .phone-code-select{
        width: 15%;
        margin-right: 16px;
      }
    }
  }

  .modal-footer{
    border-top: none;
    padding: 0;

    .form-button-accept{
      svg{
        font-size: 18px;
        margin-right: 8px;
      }
    }

    button{
      height: 32px;
      &:hover{
        background: #FF7A59;
        color: white;
      }
    }
  }
}

