.select {
  &-wrapper {
    margin-bottom: 14px;

    .label {
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 8px;
    }

    .label-icon {
      display: flex;
      margin-right: 8px;
    }

    .error-message {
      display: flex;
      flex: 1 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #FF4545
    }

    .MuiOutlinedInput-root {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .select {
    &-wrapper {
      max-width: 340px;
    }
  }
}
