.working-hour-row-container {
  display: flex;
  justify-content: space-between;

  @media(max-width:450px) {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .picker-wrapper {
    gap: 5px;

    .action-btn {
      display: inline-block;
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  .select-wrapper {
    margin: 0;
  }
  .working-hour-row-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;

    * {
      font-size: 0.9em;
    }

    .working-hours-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }

    button {
      text-transform: initial;
    }
  }
}
