.table-actions {
  display: flex;
  justify-content: flex-end;
}

.empty-data {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 12px;
}

.deactive {
  background-color: #F8F9F9;
}

.table {
  &-header {
    display: inline-block;
    font-weight: bold;
    &-container {
      display: flex;
    }
    &-sorting {
      position: relative;
      width: 10px;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}
