.ant-choices-header {
  .title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #0D172A;
    opacity: 0.64;
  }
}

.ant-choices-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    color: #FF7A59;

    &:hover {
      color: #0D172A;
    }

    &:active {
      color: #FF4545;
    }

    .icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .limit {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: #0D172A;
    opacity: 0.64;
  }
}

.ant-choices-list {
  list-style: none;
  margin: 8px 0;
}

.ant-choices-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;

  .item-left-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .item-index-wrapper {
    display: flex;
    align-items: center;
  }

  .drag-mark {
    color: #0D172A;
    opacity: 0.24;

  }

  .index-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8F9F9;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin: 0 8px;
  }

  .title {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #0D172A;
    opacity: 0.8;
    width: 60%;
  }

  .error-border {
    border: 1px solid #FF3739;
    border-radius: 4px;
  }

  .non-editable-choice {
    height: 29px;
  }

  .edit-button {
    width: 21px;
    height: 21px;
    border-radius: 4px;
  }

  .active {
    background: #1BC47D;
    color: white;

    &:hover {
      background: #1BC47D;
      color: white;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    .index-mark {
      margin: 0 8px 0 0 !important;
    }
}
