.custom-launcher-container {
  > div {
    display: block;
    &.launcher-preview {
      margin-top: 40px;
      .settings-label {
        font-weight: 400 !important;
      }
      .launcher-btn {
        margin-top: 12px;
        background: #d8392f;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
        border-radius: 56px;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        transition-duration: 0.3s;
        svg{
          transition-duration: 0.3s;
        }
        &.launcher-shape-1 {
          border-radius: 0px;
        }
        &.launcher-shape-2 {
          border-radius: 2px;
        }
        &.launcher-shape-3 {
          border-radius: 4px;
        }
        &.launcher-shape-4 {
          border-radius: 8px;
        }
        &.launcher-shape-5 {
          border-radius: 16px;
        }
        &.launcher-shape-6 {
          border-radius: 100%;
        }
        &.launcher-shape-7 {
          border-top-left-radius: 100%;
          border-top-right-radius: 100%;
          border-bottom-left-radius: 100%;
          border-bottom-right-radius: 0%;
        }
        &.launcher-shape-8 {
          border-top-left-radius: 100%;
          border-top-right-radius: 100%;
          border-bottom-left-radius: 0%;
          border-bottom-right-radius: 100%;
        }
        &.launcher-shape-9 {
          border-top-left-radius: 100%;
          border-top-right-radius: 0%;
          border-bottom-left-radius: 100%;
          border-bottom-right-radius: 100%;
        }
        &.launcher-shape-10 {
          border-top-left-radius: 0%;
          border-top-right-radius: 100%;
          border-bottom-left-radius: 100%;
          border-bottom-right-radius: 100%;
        }
        &.launcher-size-sm {
            width: 48px;
            height: 48px;
        }
        &.launcher-size-md {
            width: 56px;
            height: 56px;
        }
        &.launcher-size-lg {
            width: 64px;
            height: 64px;
        }
        &.launcher-size-xl {
            width: 80px;
            height: 80px;
        }
      }
    }
  }
}
