@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .billing-page {
    .main-cell {
      .cell-item {
        padding: 5px 0 10px 0;
        .cell-label {
          font-size: 12px;
          color: rgba(13, 23, 42, 0.64);
        }
      }
    }
    .main-cell {
      line-height: 24px;
      font-size: 14px;
      max-width: 200px;
    }
    .buttons-cell {
      padding: 12px 0 12px 20px !important;
    }
    .btn-custom {
      height: 35px;
      width: 35px;
      margin: 0 auto;
    }
    .org-table-header {
      display: none !important;
    }
    .search-input-wrapper {
      width: 100%;
      div {
        width: 100%;
      }
      i {
        font-size: 21px;
      }
    }
  }
}
