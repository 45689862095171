/*=========== Session Style NEW =========*/
.session-screen {
  display: flex;
  flex: 1 1;
  background-color: #253342;
  justify-content: flex-end;
}

.session-container {
  width: 584px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  background-color: #fff;
  padding: 100px 96px;
  box-sizing: border-box;
}

.session-logo-wrapper {
  display: flex;
  justify-content: center;

  img.logo {
    width: 100%;
  }
}

.session-invited-description {
  //font-family: 'SF Pro Display';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin-top: 52px;
  color: rgba(13, 23, 42, 0.64);
}

.session-title {
  //font-family: 'SF Pro Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  margin-top: 8px;
  color: #0D172A;
}

.session-form {
  margin-top: 16px;

  .button-separator {
    display: block;
    //font-family: 'SF Pro Display';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(13, 23, 42, 0.64);
    padding: 16px 0;
  }

  .input-wrapper {
    margin-bottom: 16px;

    .signIn-error-text {
      font-size: 13px;
      color: red;
    }

    .label {
      display: block;
      //font-family: 'SF Pro Display';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 8px;
    }

    .input {
      //font-family: 'SF Pro Display';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      border: 1px solid #D8DADD;
      border-radius: 4px;
      color: #0D172A;
      padding: 7px 12px;
      height: auto;
      box-sizing: border-box;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .button {
    //font-family: 'SF Pro Display';

    &.fluid {
      width: 100%;
    }

    .icon-wrapper {
      display: flex;
      flex: 0 1;
      justify-content: center;
      align-items: center;
      padding-right: 5px;
    }
  }
}

.password-actions {
  display: flex;
  justify-content: flex-end;
}

.sign-in-btn {
  background-color: #FF7A59 !important;
}

.sign-in-membernet-btn {
  background-color: transparent !important;
  border-color: #0315FE !important;
  color: #0315FE !important;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .session-container {
    width: 100%;
    padding: 10px 10px;

    img.logo {
      width: 296px;
      object-fit: contain;
      max-height: 200px;
    }
  }
  .password-actions {
    display: flex;
    position: relative;
    top: 180px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}

.forgot-password-back {
  position: absolute;
  top: 10%;
  font-size: 14px;
  font-style: normal;
  margin-left: 5px;

  .label {
    font-weight: 600;
    color: #464D69;
  }

  .arrow-spacing {
    margin-right: 5px;
    font-size: 14px;
    color: #464D69;
  }
}
