.store-select {
  width: 150px;

  > div {
    height: 40px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .store-select {
    width: 160px !important;
    .question-select-header {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
