.review_card {
  width: 600px;
  height: 350px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #D8DADD;
  box-sizing: border-box;
  border-radius: 8 px;
}

.post_card {
  width: 600px;
  height: 400px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #D8DADD;
  box-sizing: border-box;
  border-radius: 8 px;
}

.account_card {
  width: 600px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #D8DADD;
  box-sizing: border-box;
  border-radius: 8 px;
}

.reviewStar { 
  margin-top: 10px;
}

.reviewStar span {
  margin-right: 5px;
}

.txtMsg {
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  height: 120px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;  
  color: #0D172A;
  padding: 8px 12px;
}

.reviewArea {
  padding-left: 0;
  width: 360px;
}

.btnPost {
  color: #fff;
  background-color: #0d6efd;
}

.text-bold{
  font-weight: bold;
}

.text-body{
  text-align: center;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  color: #0D172A;
}

.option-body{
  text-align: center;;
  background-color: #FFFFFF;
  padding: 16px;
}

.review-socialIcon{
  color: white;
}

.review-title{
  color: white;
}

.btnSocialReview{
  width: 100%;
}

.thumb-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 150px !important;
  cursor: pointer;
  border: 1px solid #D8DADD;
  border-radius: 8px;
}

.thumb-img{
  width: 72px;
  height: 72px;
}