.button{
  color: #FF7A59;
  font-size: 12px;

  &:hover{
    color: #FF7A59;
  }
}

.excluded-pages-container{
  display: flex;
  margin-top: 10px;

  .section-header{
    display: flex;
    flex-direction: column;
    width: 30%;

    .label {
      font-size: 14px;
      font-weight: 500;
    }

    .label-description {
      font-size: 12px;
      color: #0D172A;
    }
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-bottom: 10px;

    .content-header{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      span{
        &:first-of-type{
          margin-right: 8px;
        }
      }
    }
  }

  .whitelist-container{
    width: 100%;
  }

  .whitelist-footer{
    display: block;
    justify-content: flex-start;
    width: 100%;
  }

  .footer-container{
    display: flex;
  }
}

.send-button{
  height: 40px;
  margin-top: 10px;
}

.color-green{
  background-color: #1BC47D;
  color: white;
  border: 0;
}

.add-form{
  width: 100%;
}
