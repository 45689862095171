.positioned-confirm-modal {
  background-color: #D8DADD;
  box-shadow: inset 0px -1px 0px rgba(13, 23, 42, 0.08);
  border-radius: 4px;
  padding: 24px 28px 28px;
  width: 380px;
  max-width: 400px;
  box-sizing: border-box;
}

.positioned-confirm-modal-header {
  position: relative;
  .title {
    padding-right: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #0D172A;
  }
}

.positioned-confirm-modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.positioned-confirm-modal-close {
  position: absolute;
}
