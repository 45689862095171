@mixin font-regular-12() {
  font-size: 12px;
  font-weight: 400;
}

@mixin font-regular-12-bold() {
  font-size: 12px;
  font-weight: 500;
}

.page-table-cell {
  @include font-regular-12-bold;
}


.zmdi-icon-arrow-up {
  font-size: 16px;
  cursor: pointer;
  height: 10px;
}

.zmdi-icon-arrow-down {
  font-size: 16px;
  cursor: pointer;
  height: 10px;
}


.settings-page-rows {

  .page-row {
    position: relative;
    padding-top: 15px;

    &::after {
      content: "";
      position: absolute;
      width: 70%;
      bottom: 0;
      left: 0;
      right: 0;
    }


    .settings-label {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    .settings-label-description {
      @include font-regular-12;
      color: $grey-1;
      padding-right: 10px;
    }
  }
}

.agent-profile-page {
  padding: 1.25rem;
  padding-bottom: 50px;
  
  .profile-rows {

    .profile-row {
      position: relative;
      padding-top: 15px;

      &::after {
        content: "";
        position: absolute;
        width: 80%;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 2px solid $grey-2;
      }

      &.no-border {
        &:after {
          border: none;
        }
      }

      .profile-label {
        font-size: 14px !important;
        font-weight: 500 !important;
      }

      .profile-label-description {
        @include font-regular-12;
        color: $grey-1;
      }

      .profile-photo {

        .max-size-photo {
          @include font-regular-12;
          word-break: break-all;
        }

        .upload-photo {
          @include font-regular-12-bold;
          color: $blue-1;
          cursor: pointer;
        }

        .remove-photo {
          @include font-regular-12-bold;
          color: $red-1;
          cursor: pointer;
        }
      }
    }
  }
}

.agents-page {
  .agents-table {
    border: 5px;
  }
}

.widget-code-page {
  .widget-code-textarea {
    height: 210px;
    font-size: 10px;
  }
}

.copy-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 14px;
}

.agent-avatar-block-wrapper {
  margin-right: 15px;
}

.agent-avatar-block {
  position: relative;

  .agent-avatar {
    color: $grey-1 !important;
    background-color: rgba(white, .1) !important;
    border: 1px solid;
  }

  .status {
    $size: 8px;
    $borderSize: 2px;
    width: $size;
    height: $size;
    border: $borderSize solid #ffffff;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    bottom: 0;
    right: 0;

    &.available {
      background-color: #1BC47D;
    }

    &.busy {
      background-color: #FAA61A;
    }

    &.offline {
      background-color: #646b77;
    }
  }
}

.auto-responder-page {
  padding: 1.25rem;
  padding-bottom: 60px!important;

  .auto-responder-textarea {
    height: 140px;
    min-height: 96px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 21px 12px;
  }

  .teaxt-area-limit {
    color: rgba(13,23,42,0.64);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  %button {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px - 2px;
    padding: 0 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

  }

  .form-button-accept {
     @extend %button;
    background: #FF7A59;
    border-color: #FF7A59;
  }

  .form-button-cancel {
     @extend %button;
    line-height: 40px;
    background: #fff;
    color: #0D172A;
    border: 1px solid #253342;
  }
}


.btn-custom {
  background: $orange-1;
  color: white;
  border-color: transparent;

  &:hover {
    background: $orange-2;
    color: white;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .auto-responder-page {
    .settings-page-rows {
      padding-bottom: 80px;
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .agent-profile-page {
    .profile-rows {
      .profile-row {
        flex-flow: column wrap;
      }
      .password-buttons {
        flex-flow: row !important;
        width: 100% !important;
        .btn {
          width: 40%;
        }
      }
    }
  }
  .settings-page-rows {
    .page-row {
      flex-flow: column wrap;
    }
  }
}
