.review-response {
  padding: 5px;
  max-width: 250px;
  span {
    font-size: 14px;
  }
}

.head-text {
  color: #0D172A;
  opacity: 0.6;
}

.react-stars {
  span{
    margin-right: 3px;
  }
}