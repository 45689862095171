.shape-container {
  .shapes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .launcher {
      width: 64px;
      height: 64px;
      margin-top: 10px;
      margin-right: 10px;
      background: linear-gradient(
        186.37deg,
        rgba(239, 239, 239, 0.4) 10.04%,
        rgba(177, 177, 177, 0.4) 89.96%
      );
      border: 1px solid #d8dadd;
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        width: 68px;
        height: 68px;
        top: -4px;
        left: -3px;
        position: absolute;
        border: 1px solid rgba($color: #d8392f, $alpha: 0);
        border-radius: 4px;
      }
      &.selected {
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
          border: 1px solid rgba($color: #d8392f, $alpha: 1);
        }
      }
      &.launcher-shape-1 {
        border-radius: 0px;
      }
      &.launcher-shape-2 {
        border-radius: 2px;
      }
      &.launcher-shape-3 {
        border-radius: 4px;
      }
      &.launcher-shape-4 {
        border-radius: 8px;
      }
      &.launcher-shape-5 {
        border-radius: 16px;
      }
      &.launcher-shape-6 {
        border-radius: 100%;
      }
      &.launcher-shape-7 {
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 0%;
      }
      &.launcher-shape-8 {
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 100%;
      }
      &.launcher-shape-9 {
        border-top-left-radius: 100%;
        border-top-right-radius: 0%;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
      }
      &.launcher-shape-10 {
        border-top-left-radius: 0%;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
      }
    }
  }
}
