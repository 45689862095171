.time-select{
  .MuiOutlinedInput-input{
    padding: 8px 55px 8px 12px;
    border: 1px solid #D8DADD;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .select-time-type {
    min-width: 100%;
    width: 100%;
  }
}
