.workflow-modal-container {
  .type-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div {
      width: 47%;
      border: 1px solid #d8dadd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      &.selected {
        border-color: #1bc47d;
        color: #1bc47d;
      }
      > label {
        width: 90%;
        margin: 0;
        text-transform: capitalize;
        > input {
          margin-right: 10px;
          margin-left: 0px;
        }
        input[type="radio"]:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }

        input[type="radio"]:checked:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: #1bc47d;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }
      }
    }
  }
}
