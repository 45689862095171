.waiting-room-wrapper {
  height: 100vh;

  .waiting-screen {
    width: 40%;

    .title-question {
      font-size: 24px;
    }

    .settings-screen {
      background-color: #253342;
      height: 50vh;
      width: 100%;
    }
    .waiting-screen-error {
      color: white;
      font-size: 25px;
      position: relative;
      top: 40%;
      text-align: center;
    }
    .settings-screen-camera {
      height: 100%;

      .video-preview {
        width: 100%    !important;
        height: 100%   !important;
      }
    }

    .settings-controls {
      position: absolute;
      bottom: 10%;
      left: 35%;
      button {
        .button-text {
          margin-left: 10px;
        }
      }
    }

    .customer-section {
      padding-top: 40px;

      .customer-avatar {

        .large-size {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
      }

      .customer-activity {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .camera-disabled {
    display: none;
  }

  &.waiting-room-wrapper-mobile {
    display: block!important;

    .room-call-header {
      position: absolute;
      left: 0;
      top: 0;
      height: 95px;
      width: 100%;
      z-index: 3;
      overflow: hidden;

      &::before {
        position: absolute;
        background: #33475B;
        width: 100%;
        height: 100%;
        content: " ";
        left: 0;
        top: 0;
      }

      &.is-blurred::before {
        position: absolute;
        background: #253342;
        width: 100%;
        height: 100%;
        content: " ";
        left: 0;
        top: 0;
        opacity: 0.3;
      }

      .ti-arrow-left {
        position: absolute;
        bottom: 0;
        left: 0;
        color: white;
        padding: 10px
      }
    }

    .waiting-screen {
      display: block!important;
      width: 100%;
      height: 100%;
    }

    .title-question {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 4;
      color: white;
      line-height: 24px;
    }

    .settings-controls {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;
      padding: 20px 0;
      overflow: hidden;

      .settings-controls-blurred {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #253342;
        opacity: 0.3;
      }

      .settings-controls-delimiter {
        height: 55px;
        border-left: 1px solid #33475B;
        margin-left: 18px;
        opacity: 0.6;
      }
    }

    video {
      object-fit: cover;
      object-position: center center;
    }

    .customer-section {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 4;
      padding: 0;
      margin: 0;

      >div {
        padding-top: 0!important;
      }
    }

    .settings-screen {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }

    .camera-disabled {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: #253342;

      >div {
        position: absolute;
        left: 0;
        top: 50%;
        text-align: center;
        margin-top: -80px;
        width: 100%;

        >img {
          display: block;
          margin: 20px auto;
          border-radius: 50%;
          width: 100px;
          height: 100px;
        }
        > span {
          margin: 0 5px;
          color: #888888;
          position: relative;
          top: 7px;
        }

        >b {
          color: white;
          margin-right: 10px;
        }
      }
    }
  }
}

.btn-join-now {
  background: $red-1;
  color: white;
  padding: 1rem 2.5rem !important;
  font-weight: bold !important;
  border: none;
  width: 245px !important;
  height: 60px !important;
  &:hover {
    background: $red-2;
    color: white;
  }
}

.video-space-wrapper {

  .main-container {
    position: relative;
    height: 90vh;
    background-color: #0D172A;

    .size-avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .local-video-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      height: 150px;

      video {
        width: 200px !important;
        height: 150px !important;
      }
    }
  }

  .toolbar {
    height: 10vh;
    background-color: white;
    position: absolute;
    width: 100%;
    .list-products {}

    .toolbar-controls {
      width: 25%;
    }

    .toolbar-chat {}
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: portrait) {
  .video-space-wrapper {
    .main-container {
      .video-wrapper {
        height: 90vh;
      }
    }
  }
  .waiting-room-wrapper {
    .waiting-screen {
      width: 100%;
      .customer-section {
        position: absolute;
        bottom: 5px;
      }
    }
  }
}

/* FIX layout for android tablets */
@media screen and (min-width: 640px) and (max-width: 1280px) {
  .android-tablet-device {
    .video-space-wrapper {
      height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100) !important;
      width: 100vw;
      display: flex;
      flex-direction: column;
      .main-container {
        flex-grow: 1;
        overflow-y: hidden;
        height: auto !important;
        .video-container.with-chat {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          .incoming-call-chat {
            display: flex;
            flex-direction: column;
            width: 100%;
            .header {
              width: 100%;
            }
            .chat-main-body {
              display: flex;
              flex-direction: column;
              .rct-scroll {
                flex-grow: 1;
                .chat-body {
                  width: 100%;
                }
              }
              flex-grow: 1;
            }
            .chat-footer {
              position: sticky;
              bottom: 0; // TODO remove this dirty fix!!
              .sender-wrap {
                width: auto;
                .msg-form {
                  display: flex;
                  flex-direction: row;
                  flex-grow: 1;
                  justify-content: space-between;
                  .submit-button {
                    position: relative;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }
      .toolbar {
        position: relative;
        height: 10vh !important;
      }
    }
  }
}
/* FIX layout for apple tablets */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ios-tablet-device {
    .video-space-wrapper {
      height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100) !important;
      width: 100vw;
      display: flex;
      flex-direction: column;
      .main-container {
        flex-grow: 1;
        overflow-y: hidden;
        height: auto !important;
        .video-container.with-chat {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          .incoming-call-chat {
            display: flex;
            flex-direction: column;
            width: 100%;
            .header {
              width: 100%;
            }
            .chat-main-body {
              display: flex;
              flex-direction: column;
              .rct-scroll {
                flex-grow: 1;
                .chat-body {
                  width: 100%;
                }
              }
              flex-grow: 1;
            }
            .chat-footer {
              position: sticky;
              bottom: 0; // TODO remove this dirty fix!!
              .sender-wrap {
                width: auto;
                .msg-form {
                  display: flex;
                  flex-direction: row;
                  flex-grow: 1;
                  justify-content: space-between;
                  .submit-button {
                    position: relative;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }
      .toolbar {
        position: relative;
        height: 10vh !important;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .btn-join-now {
    width: unset !important;
    height: unset !important;
  } 
}
