.messenger-wrapper {
  display: flex;
  /* We need this fix at least for desktop, so later we can spread it for tablet, but not for now */
  @media only screen and (min-device-width : 1281px) {
    height: calc(100vh - 64px); // 64 - header bar height
    overflow-y: hidden;
    .chat-wrapper {
      flex-grow: 1;
      .user-list-wrap {
        max-width: 50%;
      }
      .chat-content {
        @media only screen and (min-device-width : 1680px) {
          max-width: none; // We don't need any limit for specific case with wide screen
        }
        .chat-footer {
          position: static;
          .MuiTab-root {
            flex-grow: 1;
            min-width: 100px !important;
          }
        }
      }
    }
  }
}
.new-line{
  white-space: pre-line;
}
.fake-link{
  color: #5D92F4;
  cursor: pointer;
}

.type-infoRequest{
  font-size: 14px;
}

.notes-container {
  max-height: 30vh;
  padding-right: 12px;
  padding-bottom: 30px;
  overflow-y: auto;
}

.private-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(250, 166, 26, 0.16);
  color: #0D172A;
  padding: 8px 5px 8px 8px;
  border: 1px solid rgba(250, 166, 26, 0.16);
  border-radius: 4px;
  transition: .1s;
  font-size: 12px;

  form{
    width: 80%;
  }

  &:hover{
    border: 1px solid #eea941;
  }
}
.send-note-icon {
  color: #696969;
  cursor: pointer;
  :hover {
    color: #bfbfbf
  }
}
.icon{
  &:hover{
    color: #FAA61A;
  }
}

.input-label {
  top: -12px;
}

.private-note-content {
  overflow-wrap: break-word;
  overflow-x: auto;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px) {
  .messenger-wrapper {
    overflow: hidden;
    height: 100%;
  }
  .chat-wrapper {
    overflow-y: scroll;
    .user-list-wrap {
      .chat-list-opened {
        display: block !important;
      }
      .chat-list {
        display: none;
      }
      .search-wrapper{
        display: none;
      }
    }
    .user-list-wrap {
      .MuiDrawer-paper {
        position: relative;
        top: 1px
      }
      .sidebar-opened {
        .chat-sidebar {
          display: flex;
        }
      }
      .chat-sidebar {
        display: block;
        .users-list-wrapper {
          width: 100%;
        }
      }
  }
  }
}

