.r-custom-async-select-root {
  >:nth-child(2) {
    > :first-child {
      max-width: 160px;
      overflow-x: hidden;
    }
  }
}
.expoImg{
  vertical-align: top;
}
@media only screen and (max-width: 600px) {
  .r-custom-async-select-root {
    >:nth-child(2) {
      > :first-child {
        max-width: 300px;
        overflow-x: hidden;
      }
    }
  }
}
