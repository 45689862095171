.accountItem{
  width: 100%;
  display: flex;
  border: 1px solid #D8DADD;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px 0;
}

.accountTitle{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.accountInfo{
  display: block;
}

.accountName{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #0D172A;
}

.accountCheck{
  display: flex;
  align-items: center;
}

.btnCopy{
  width: 100%;
  height: 40px;
  align-self: end;
  margin-bottom: 15px;
}

.reviewMsg{
  border: 1px solid #C4C4C4;
  width: 100%;
  border-radius: 4px;
  height: 80px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;  
  color: #0D172A;
  padding: 8px 12px;
}

.socialIcon{
  font-size: 12px;
  margin-right: 3px;
}