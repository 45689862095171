%button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px - 2px;
  padding: 0 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.style-your-messenger-container {
  .form-button-cancel {
    @extend %button;
    line-height: 40px;
    background: #fff;
    color: #0d172a;
    border: 1px solid #253342;
  }

  .form-button-accept {
    @extend %button;
    background: #ff7a59;
    border-color: #ff7a59;
  }
}
