.control-header-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    span{
        font-size: 14px;
        color: #0D172A;
    }
    svg{
        width: 12px;
        margin-right: 5px;
        fill:  rgba(13, 23, 42, 0.64)           ;
    }
}