.user-client-info-wrapper {
  padding: 1.625rem 1.25rem;
  border-bottom: 1px solid #ECECEE;
}

.user-client-info {
  margin-top: 10px;
  font-size: 14px;
  margin-left: 8px;
}

.user-info-row {
  margin-bottom: 5px;
  align-items: center;
  display: grid;
  grid-template-columns: 30px 111%;
  .icon {
    font-size: 20px;
    opacity: 0.7;
    margin-right: 8px;
  }
}

.user-info-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  font-size: 12px;
}

.editable{
  display: flex;
  justify-content: space-between;
}
